<template>
  <main class="index">
    <section class="site-desc">
      <h1>Аниме онлайн, комиксы манга и ранобэ новеллы</h1>
      <!-- <h2>О проекте ANIME777</h2> -->
      <div class="content">
        <p>
          Йо! Скорее всего вы искали, где <strong>посмотреть аниме онлайн</strong> или почитать <strong>комиксы манга</strong>.
          А может быть вас интересовали <strong>ранобэ новеллы</strong>?
        </p>
        <p>
          В любом случае это не важно! Почему? Потому что у нас есть все: вашему вниманию
          наши <strong>самые полные в ру-нете каталоги с аниме, ранобэ и мангой</strong>.
        </p>
        <p>
          <strong>Все жанры и озвучки популярных студий</strong>. Для любителей оригинальной озвучки можем
          предложить вариант с субтитрами.
        </p>
        <p>
          Обратите внимание! <strong>Наш сайт это не только про просмотр аниме онлайн или чтение манги + ранобэ</strong>.
          У нас вы также можете пообщаться с любителями жанра, анимешниками. Ежедневно к нам
          присоединяются сотни новых пользователей. Уверены вы сможете найти новых друзей.
        </p>
        <p>
          Дочитали до этого момента? Тогда мы <strong>поделимся с вами секретом</strong>! На нашем сайте есть
          совместный просмотр аниме, игры аниме тематики, подбор рандомного аниме, аватарки, обои,
          списки персонажей из аниме и многое другое. Где это все найти? <strong>Меню в верхней части сайта</strong> -
          ваш лучший друг и потеводитель по сайту, используйте его. Там вы сможете найти все, что вам
          нужно.
        </p>
        <p>
          Если у вас возникнут вопросы напишите нам, мы будем рады вам помочь. А если появятся идеи, готовы выслушать.
          <span @click="$store.commit('toggleFeedback', true)" class="fback">Написать админу</span>
        </p>
        <p>
          <strong>Удачи в изучении этого увлекательного и удивительного мира японской анимации!</strong>
          (<nuxt-link to="/about-anime">Что такое аниме?</nuxt-link>)
        </p>
      </div>
    </section>

    <section v-for="(genre, i) in obj.collections" :key="`index-collections-genre-${i}`">
      <relatedCollection :docs="genre" />
    </section>

    <relatedComics v-if="showBlock['comics'] && obj.comics" :docs="obj.comics" />
    <div v-else data-block-id="comics" class="lazy-block" />

    <relatedRanobes v-if="showBlock['ranobes'] && obj.ranobes" :docs="obj.ranobes" />
    <div v-else data-block-id="ranobes" class="lazy-block" />
  </main>
</template>

<script>
export default {
  components: {
    relatedComics: () => import('~/components/relatedComics.vue'),
    relatedRanobes: () => import('~/components/relatedRanobes.vue'),
    relatedCollection: () => import('~/components/relatedCollection.vue')
  },
  async asyncData({ $axios, query, redirect }) {
    if (Object.keys(query).length && !query.ref) {
      return redirect('/')
    }

    let obj = {
      comics: [],
      ranobes: [],
      released: [],
      updates: [],
      collections: []
    }

    const { data } = await $axios.get('/api/indexdata')

    if (!data.error) {
      obj = data
    }

    return {
      obj,
      showBlock: {
        released: false, updates: false, comics: false, ranobes: false,
        ggl1: false, ggl2: false
      }
    }
  },
  mounted() {
    const updateBlocks = document.querySelectorAll('.slider')
    if (updateBlocks) {
      updateBlocks.forEach(block => {
        block.addEventListener('scroll', this.lazyLoadImgs)
      })
    }
    window.addEventListener('scroll', this.lazyBlocks)
  },
  beforeDestroy() {
    const updateBlocks = document.querySelectorAll('.slider')
    if (updateBlocks) {
      updateBlocks.forEach(block => {
        block.removeEventListener('scroll', this.lazyLoadImgs)
      })
    }
  },
  methods: {
    lazyBlocks() {
      const doc = document.documentElement.clientHeight
      const list = document.querySelectorAll('.lazy-block')
      list.forEach(block => {
        const visible = doc - block.getBoundingClientRect().top
        const blockId = block.dataset.blockId
        if (visible > 0 && !this.showBlock[blockId]) {
          this.showBlock[blockId] = true
        }
      })
    }
  }
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
